<template>
    <b-card title="Mise à jour des points de vente">
        <b-row class="mb-3 align-items-center">
            <b-col cols="3">
                <b-form-group label="Sélectionner une marque" label-for="input-select-brand">
                    <b-form-select id="input-select-brand" v-model="selectedBrand" :options="brands" class="select-height"></b-form-select>
                </b-form-group>
            </b-col>
        </b-row>
        <b-tabs style="margin-top: 20px">
            <b-tab title="Difference Import/BD">
                <b-button size="sm" variant="primary" @click="downloadReport(selectedBrand)" style="margin-left: 10px"> Telecharger </b-button>
                <b-col cols="9" class="d-flex justify-content-end">
                    <b-button size="sm" variant="primary" @click="comparePos(selectedBrand)" class="btn-height">Compare BD</b-button>
                    <b-button size="sm" variant="primary" @click="$refs['modal-upload-fichier'].show()" class="ml-2 btn-height">Compare Fichier</b-button>
                </b-col>
                <b-col cols="10" v-if="missingInArea.missingInArea && missingInArea.missingInArea.length > 0">
                    <div class="table thead th">{{ 'Missing POI AREAS :' }}</div>
                    <div class="nav-tabs">{{ missingInArea.missingInArea }}</div>
                </b-col>
                <b-col cols="10" v-if="missingPDV.missingPDV && missingPDV.missingPDV.length > 0">
                    <div class="table thead th">{{ 'Missing PDV :' }}</div>
                    <div class="nav-tabs">{{ missingPDV.missingPDV }}</div>
                </b-col>
                <b-col cols="10" v-if="errors">
                    <div class="table thead th">{{ 'Errors :' }}</div>
                    <div class="nav-tabs">{{ errors }}</div>
                </b-col>
                <pos-table :brand="selectedBrand" :posInfo="pos" :subByPass="subByPass"></pos-table>
            </b-tab>
            <b-tab title="Faire l'import">
                <import-table :brand="selectedBrand" :subByPass="subByPass"></import-table>
            </b-tab>
            <b-tab title="Run Script">
                <run-script :brand="selectedBrand" :subByPass="subByPass"></run-script>
            </b-tab>
            <b-tab title="Copy Import">
                <copy-import :brand="selectedBrand" :subByPass="subByPass"></copy-import>
            </b-tab>
        </b-tabs>
        <b-modal ref="modal-upload-fichier" hide-footer title="Télécharger Fichier">
            <span> Comparaison avec "{{ selectedBrand }}" - si vous le souhaitez, changez de marque </span>
            <b-row>
                <b-col cols="12" class="d-flex align-items-stretch">
                    <b-btn style="margin-right: 10px" variant="primary" @click="openFilePoi">
                        <feather-icon icon="UploadIcon" />
                        Importer fichier POI
                    </b-btn>
                    <b-form-file ref="filePoi" v-model="poi" class="d-none" accept=".csv"></b-form-file>
                    <b-btn style="margin-left: 10px" variant="primary" @click="openFileArea">
                        <feather-icon icon="UploadIcon" />
                        Importer fichier POI_AREAS
                    </b-btn>
                    <b-form-file ref="fileArea" v-model="area" class="d-none" accept=".csv"></b-form-file>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-between">
                <b-button class="mt-3" @click="cancel()">Annuler</b-button>
                <b-button class="mt-3" variant="primary" @click="compareFile(poi, area)"> Confirmer </b-button>
            </div>
        </b-modal>
    </b-card>
</template>

<script>
    import { mapActions } from 'vuex';
    import PosTable from './pos-table.vue';
    import ImportTable from './import-table.vue';
    import RunScript from './run-script.vue';
    import CopyImport from './copy-import.vue';
    import { mapGetters } from 'vuex';
    import { format } from '@fast-csv/format';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

    export default {
        components: {
            PosTable,
            ImportTable,
            RunScript,
            CopyImport,
        },
        data() {
            return {
                brands: [],
                selectedBrand: 'citroen',
                subByPass: false,
                brand: '',
                missingInArea: [],
                missingPDV: [],
                pos: [],
                blobData: [],
                poi: null,
                area: null,
                errors: '',
            };
        },
        computed: {
            ...mapGetters({
                successToast: 'app/successToast',
                errorToast: 'app/errorToast',
            }),
        },
        mounted() {
            this.init();
        },
        methods: {
            ...mapActions({
                getBrands: 'app/getBrands',
                getComparisonPoi: 'app/getComparisonPoi',
                getComparisonFile: 'app/getComparisonFile',
            }),
            init() {
                this.$root.toggleGlobalLoading(false);
                let promises = [this.fetchBrands()];
                Promise.all(promises)
                    .then((res) => {
                        this.$root.toggleGlobalLoading(false);
                    })
                    .catch((e) => {
                        console.error(e);
                        this.errors = e;
                        this.$toast(this.errorToast('Erreur lors de la récupération des données'));
                    });
            },
            fetchBrands() {
                this.$set(this, 'brand', this.selectedBrand);
                return this.getBrands().then((res) => {
                    const brands = res.data.map((b) => b.name);
                    this.$set(this, 'brands', brands);
                });
            },
            comparePos(selectedBrand) {
                this.missingInArea = [];
                this.errors = '';
                this.pos = [];
                this.$root.toggleGlobalLoading(true);
                let payload = { brand: selectedBrand };
                this.getComparisonPoi(payload)
                    .then((res) => {
                        this.$root.toggleGlobalLoading(false);
                        this.pos = res.data.result;
                        this.missingInArea = res.data.missingPoiArea;
                        this.missingPDV = res.data.missingPDV;
                        this.$root.toggleGlobalLoading(false);
                        this.$toast({
                            component: 'toast',
                            props: {
                                title: 'Bien comparé',
                                icon: 'smile',
                                variant: 'success',
                            },
                        });
                    })
                    .catch((e) => {
                        this.errors = e.response.data.error;
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Connection erreur',
                                icon: 'x-circle',
                                variant: 'danger',
                            },
                        });
                    })
                    .finally((e) => {
                        this.$root.toggleGlobalLoading(false);
                    });
            },
            compareFile(poi, area) {
                this.missingPoiArea = [];
                this.errors = '';
                this.pos = [];
                let formData = new FormData();
                if (poi) {
                    formData.append('poi', poi);
                }
                if (area) {
                    formData.append('area', area);
                }
                let payload = {
                    form: formData,
                    brand: this.selectedBrand,
                };
                this.$root.toggleGlobalLoading(true);
                this.$store
                    .dispatch('app/getComparisonFile', payload)
                    .then((res) => {
                        this.pos = res.data.result;
                        this.missingInArea = res.data.missingPoiArea;
                        this.missingPDV = res.data.missingPDV;
                        this.$refs['modal-upload-fichier'].hide();
                    })
                    .catch((e) => {
                        this.errors = e.response.data.error;
                        console.log(e.response.data);
                        this.$toast(this.errorToast('Erreur'));
                    })
                    .finally((e) => {
                        this.$root.toggleGlobalLoading(false);
                        this.$refs['modal-upload-fichier'].hide();
                    });
            },
            cancel() {
                this.$refs['modal-upload-fichier'].hide();
            },
            openFilePoi() {
                this.$refs.filePoi.$refs.input.click();
            },
            openFileArea() {
                this.$refs.fileArea.$refs.input.click();
            },
            downloadReport() {
                const formattedCsv = format({ headers: true, delimiter: ';' });

                this.pos.forEach((row) => formattedCsv.write(row));

                formattedCsv.on('data', (chunk) => {
                    this.blobData.push(chunk);
                });

                formattedCsv.on('end', () => {
                    const blob = new Blob(this.blobData, { type: 'text/csv' });

                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(blob);
                    link.download = `${this.descriptionToUse} ${this.selectedBrand}.csv`;

                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                    this.blobData = [];
                });
                formattedCsv.end();
            },
        },
    };
</script>

<style lang="scss">
    .select-height,
    .btn-height {
        height: 38px;
    }

    .container-pos {
        padding-left: 0 !important;
    }
</style>
